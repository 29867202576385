import { Component, Vue, Inject } from 'vue-property-decorator';

import AlertService from '@/shared/alert/alert.service';

import { IAppUserDTO, AppUserDTO } from '@/shared/model/app-user.dto';
import CustomAppUserService from './custom-app-user.service';
import { required } from 'vuelidate/lib/validators';

const validations: any = {
  appUser: {
    email: {
      required,
    },
    name: {},
    street: {},
    zip: {},
    city: {},
  },
};

@Component({
  validations,
})
export default class AppUserUpdate extends Vue {
  public appUser: IAppUserDTO = new AppUserDTO();
  public isSaving = false;
  @Inject('customAppUserService') private customAppUserService: () => CustomAppUserService;
  @Inject('alertService') private alertService: () => AlertService;

  public async save(): Promise<void> {
    this.isSaving = true;
    try {
      await this.customAppUserService().createDTO(this.appUser);

      this.isSaving = false;
      this.$router.go(-1);
      this.alertService().showInfo(this, 'Der Benutzer wurde erfolgreich angelegt.');
    } catch (error) {
      this.isSaving = false;
      this.alertService().showHttpError(this, error.response);
    }
  }

  public previousState(): void {
    this.$router.go(-1);
  }
}
