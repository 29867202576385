export interface IAppUserDTO {
  id?: number;
  name?: string | null;
  street?: string | null;
  zip?: string | null;
  city?: string | null;
  email?: string | null;
  createdDate?: Date | null;
}

export class AppUserDTO implements IAppUserDTO {
  constructor(
    public id?: number,
    public name?: string | null,
    public street?: string | null,
    public zip?: string | null,
    public city?: string | null,
    public email?: string | null,
    public createdDate?: Date | null
  ) {}
}
