var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "boxed-layout" }, [
    _c("div", [
      _c(
        "form",
        {
          attrs: { name: "editForm", role: "form", novalidate: "" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.save()
            },
          },
        },
        [
          _c(
            "h2",
            {
              attrs: {
                id: "cdpRisikoApp.appUser.home.createOrEditLabel",
                "data-cy": "AppUserCreateUpdateHeading",
              },
            },
            [_vm._v("\n          Neuen User anlegen\n        ")]
          ),
          _vm._v(" "),
          _c("div", [
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                {
                  staticClass: "form-control-label",
                  attrs: { for: "app-user-name" },
                },
                [_vm._v("Name")]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.$v.appUser.name.$model,
                    expression: "$v.appUser.name.$model",
                  },
                ],
                staticClass: "form-control",
                class: {
                  valid: !_vm.$v.appUser.name.$invalid,
                  invalid: _vm.$v.appUser.name.$invalid,
                },
                attrs: {
                  type: "text",
                  name: "name",
                  id: "app-user-name",
                  "data-cy": "name",
                },
                domProps: { value: _vm.$v.appUser.name.$model },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.$v.appUser.name, "$model", $event.target.value)
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                {
                  staticClass: "form-control-label",
                  attrs: { for: "app-user-zip" },
                },
                [_vm._v("PLZ")]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.$v.appUser.zip.$model,
                    expression: "$v.appUser.zip.$model",
                  },
                ],
                staticClass: "form-control",
                class: {
                  valid: !_vm.$v.appUser.zip.$invalid,
                  invalid: _vm.$v.appUser.zip.$invalid,
                },
                attrs: {
                  type: "text",
                  name: "zip",
                  id: "app-user-zip",
                  "data-cy": "zip",
                },
                domProps: { value: _vm.$v.appUser.zip.$model },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.$v.appUser.zip, "$model", $event.target.value)
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                {
                  staticClass: "form-control-label",
                  attrs: { for: "app-user-city" },
                },
                [_vm._v("Ort")]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.$v.appUser.city.$model,
                    expression: "$v.appUser.city.$model",
                  },
                ],
                staticClass: "form-control",
                class: {
                  valid: !_vm.$v.appUser.city.$invalid,
                  invalid: _vm.$v.appUser.city.$invalid,
                },
                attrs: {
                  type: "text",
                  name: "city",
                  id: "app-user-city",
                  "data-cy": "city",
                },
                domProps: { value: _vm.$v.appUser.city.$model },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.$v.appUser.city, "$model", $event.target.value)
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                {
                  staticClass: "form-control-label",
                  attrs: { for: "app-user-street" },
                },
                [_vm._v("Straße")]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.$v.appUser.street.$model,
                    expression: "$v.appUser.street.$model",
                  },
                ],
                staticClass: "form-control",
                class: {
                  valid: !_vm.$v.appUser.street.$invalid,
                  invalid: _vm.$v.appUser.street.$invalid,
                },
                attrs: {
                  type: "text",
                  name: "street",
                  id: "app-user-street",
                  "data-cy": "street",
                },
                domProps: { value: _vm.$v.appUser.street.$model },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.$v.appUser.street,
                      "$model",
                      $event.target.value
                    )
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                {
                  staticClass: "form-control-label",
                  attrs: { for: "app-user-email" },
                },
                [_vm._v("E-Mail")]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.$v.appUser.email.$model,
                    expression: "$v.appUser.email.$model",
                  },
                ],
                staticClass: "form-control",
                class: {
                  valid: !_vm.$v.appUser.email.$invalid,
                  invalid: _vm.$v.appUser.email.$invalid,
                },
                attrs: { type: "text", name: "street", id: "app-user-email" },
                domProps: { value: _vm.$v.appUser.email.$model },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.$v.appUser.email,
                      "$model",
                      $event.target.value
                    )
                  },
                },
              }),
              _vm._v(" "),
              _vm.$v.appUser.email.$anyDirty && _vm.$v.appUser.email.$invalid
                ? _c("div", [
                    !_vm.$v.appUser.email.required
                      ? _c("small", { staticClass: "form-text text-danger" }, [
                          _vm._v(
                            "\n                Pflichtfeld\n              "
                          ),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "text-right" }, [
            _c(
              "button",
              {
                staticClass: "btn",
                attrs: {
                  type: "button",
                  id: "cancel-save",
                  "data-cy": "entityCreateCancelButton",
                },
                on: {
                  click: function ($event) {
                    return _vm.previousState()
                  },
                },
              },
              [
                _c("font-awesome-icon", { attrs: { icon: "ban" } }),
                _vm._v(" "),
                _c("span", [_vm._v("Abbrechen")]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: {
                  type: "submit",
                  id: "save-entity",
                  "data-cy": "entityCreateSaveButton",
                  disabled: _vm.$v.appUser.$invalid || _vm.isSaving,
                },
              },
              [
                _c("font-awesome-icon", { attrs: { icon: "save" } }),
                _vm._v(" "),
                _c("span", [_vm._v("User anlegen")]),
              ],
              1
            ),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }